<template>
    <div class="card shadow-sm col-lg-12 h-100 overflow-hidden">
        <b-tabs
            card
            v-model="tabIndex"
            active-nav-item-class="font-weight-bold text-uppercase h-100"
            nav-class="col-lg-10"
            content-class="col-lg-12 p-0"
        >
            <b-tab class="p-0 h-100" lazy>
                <template #title>
                    {{ $t("content.title") }} ({{ contents_count }})
                </template>
                <library-contents
                    ref="contents"
                    @click="showPreview"
                    :queryParam="contentsQueryParam"
                    :key="contentsTabKey"
                    :show_multi_select="select_contents"
                    @selected="select_unselect_content"
                />
            </b-tab>

            <b-tab class="p-0 h-100" lazy>
                <template #title>
                    <i class="zmdi zmdi-timer">
                        {{ $t("content.expired_content_title") }} ({{
                            expired_contents_count
                        }})
                    </i>
                </template>

                <library-contents
                    ref="contents_expired"
                    @click="showPreview"
                    :queryParam="expiredContentQueryParam"
                    :key="expiredContentsTabKey"
                    :show_multi_select="select_contents"
                    @selected="select_unselect_content"
                />
            </b-tab>

            <b-tab class="p-0 h-100" lazy>
                <template #title>
                    <i class="zmdi-delete zmdi danger">
                        {{ $t("content.deleted_content_title") }} ({{
                            deleted_contents_count
                        }})
                    </i>
                </template>

                <library-contents
                    ref="contents_expired"
                    @click="showPreview"
                    :queryParam="deletedContentQueryParam"
                    :key="deleteContentsTabKey"
                    :trash="true"
                    :show_multi_select="select_contents"
                    @selected="select_unselect_content"
                />
            </b-tab>
        </b-tabs>

        <div
            class="col-8 filters d-flex align-items-center justify-content-end"
        >
            <search
                media_type="Content"
                @change="searchTextChanged"
                v-show="!select_contents"
            />
            <contents-sorting
                @change="sortingChanged"
                v-show="!select_contents"
            />
            <b-button
                variant="primary"
                class="float-right ml-2"
                @click="showHideSelection()"
            >
                {{
                    select_contents
                        ? $t("common_words.deselect")
                        : $t("common_words.select")
                }}
            </b-button>
            <b-button
                variant="danger"
                class="float-right ml-2"
                @click="delete_files"
                v-show="select_contents"
            >
                {{ $t("common_words.delete") }}
            </b-button>
            <b-button
                variant="primary"
                class="float-right ml-2"
                v-show="!select_contents"
                @click="showUploadSelection()"
            >
                {{ $t("content.upload_btn") }}
            </b-button>
        </div>

        <upload-selection />
        <content-previewer />
    </div>
</template>

<script>
    import EventBus from "../core/event-bus";
    import ContentPreviewer from "../Previewer/ContentPreviewer";
    import NoContent from "../components/NoContent";
    import LibraryContents from "./LibraryContents";
    import Search from "../components/Search";
    import UploadSelection from "../upload/UploadSelection.vue";
    import ContentsSorting from "./ContentsSorting.vue";
    import {
        getContentsSortingField,
        getContentsSortingOrderBy,
    } from "../core/UserPreferences";

    export default {
        props: {},

        data() {
            return {
                files: [],
                tabIndex: 0,
                searchText: "",
                contents_count: null,
                expired_contents_count: null,
                deleted_contents_count: null,
                contentsTabKey: true,
                expiredContentsTabKey: true,
                deleteContentsTabKey: true,
                select_contents: false,
                selected_contents: [],
                contentsQueryParam: {
                    expired: false,
                    order: getContentsSortingField(),
                    order_by: getContentsSortingOrderBy(),
                    page: 1,
                    query: "",
                },
                expiredContentQueryParam: {
                    expired: true,
                    order: getContentsSortingField(),
                    order_by: getContentsSortingOrderBy(),
                    page: 1,
                    query: "",
                },
                deletedContentQueryParam: {
                    deleted: true,
                    order: getContentsSortingField(),
                    order_by: getContentsSortingOrderBy(),
                    page: 1,
                    query: "",
                },
            };
        },
        watch: {
            tabIndex: {
                immediate: false,
                handler(val, oldVal) {
                    this.queryParamChanged();
                },
            },
            searchText: {
                immediate: false,
                handler(val, oldVal) {
                    this.contentsQueryParam["query"] = this.searchText;
                    this.expiredContentQueryParam["query"] = this.searchText;
                    this.queryParamChanged();
                },
            },
        },
        components: {
            File,
            NoContent,
            LibraryContents,
            ContentPreviewer,
            Search,
            UploadSelection,
            ContentsSorting,
        },

        created: function () {
            let _this = this;
            EventBus.$on("contentsCountUpdated", function (contents_count) {
                _this.update_contents_count(contents_count);
            });
            EventBus.$on("CountUpdated", function update_count(counts) {
                _this.update_count(counts);
            });
        },

        beforeDestroy() {
            EventBus.$off("contentsCountUpdated");
            EventBus.$off("expiredContentsCountUpdated");
        },

        methods: {
            update_count: function (totalCount) {
                this.contents_count = totalCount["contents_count"];
                this.expired_contents_count = totalCount["expired_count"];
                this.deleted_contents_count = totalCount["deleted_count"];
            },

            searchTextChanged(searchText) {
                this.searchText = searchText;
            },

            sortingChanged(sort_option) {
                this.contentsQueryParam["order"] = sort_option.order.value;
                this.contentsQueryParam["order_by"] = sort_option.order_by;

                this.expiredContentQueryParam["order"] = sort_option.order.value;
                this.expiredContentQueryParam["order_by"] = sort_option.order_by;

                this.queryParamChanged();
            },

            queryParamChanged() {
                if (this.tabIndex == 0) {
                    this.contentsTabKey = !this.contentsTabKey;
                } else {
                    this.expiredContentsTabKey = !this.expiredContentsTabKey;
                }
            },

            showPreview: function (content) {
                EventBus.$emit("showModel", content);
            },

            showUploadSelection: function () {
                EventBus.$emit("showUploadSelectionModel");
            },

            showHideSelection: function () {
                this.select_contents = !this.select_contents;
            },

            delete_files: function () {
                EventBus.$emit("delete_files");
            },

            select_unselect_content: function(selected){
                if(!selected){
                    this.selected_contents = [];
                }
               this.select_contents = selected;
            }
        },
    };
</script>
<style scoped>
.filters {
    position: absolute;
    top: 6px;
    right: 10px;
}
</style>
