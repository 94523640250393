var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contents.length > 0)?_c('scroll-pagination',{key:_vm.shouldRerenderList,attrs:{"scrollFunction":_vm.handleScroll,"loading":_vm.loading,"page":_vm.page,"total_pages":_vm.total_pages}},[_vm._l((this.contents),function(content){return _c('library-content',{directives:[{name:"show",rawName:"v-show",value:(_vm.contents.length),expression:"contents.length"}],key:content.id,attrs:{"content":content,"show_multi_select":_vm.show_multi_select},on:{"deleted":_vm.reload_contents,"updated":_vm.reload_contents,"click":_vm.listenAction,"selected":_vm.select_unselect_content}})}),_vm._v(" "),(_vm.selected_content != null)?_c('edit-file',{attrs:{"content_id":_vm.selected_content.id},on:{"updated":_vm.reload_contents}}):_vm._e(),_vm._v(" "),(_vm.selected_content != null)?_c('expiration',{attrs:{"content_id":_vm.selected_content.id},on:{"updated":_vm.reload_contents}}):_vm._e()],2):_c('div',{staticClass:"d-flex h-100 m-auto"},[(
            !_vm.loading &&
            !this.contents.length &&
            !this.queryParam.deleted &&
            this.queryParam.query.length == 0
        )?_c('no-content',{attrs:{"title":_vm.$t('content.no_content_title'),"imageName":'no_content.png',"actionText":_vm.$t('content.no_content'),"nextSteptText":_vm.$t('content.no_content_next_step')},on:{"clicked":_vm.showUploadDialog}}):_vm._e(),_vm._v(" "),(
            !this.loading &&
            !this.contents.length &&
            this.queryParam.query.length > 0
        )?_c('no-search-result',{attrs:{"searchText":this.queryParam.query}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }