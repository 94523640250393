import Vue from 'vue';
import Http from '../core/http';
import queryString from 'query-string'

const ContentService = {
	list(params) {
		let query_string = queryString.stringify(params)
		return Http.get('user/contents.json?' +  query_string);
	},

	show(id) {
		return Http.get('user/contents/' + id + '.json');
	},

	get(id) {
		return Http.get('user/contents/' + id + '.json');
	},

	create(params) {
		if(typeof params['metadata'] == 'object') {
		    params['metadata'] = JSON.stringify(params['metadata'])
		}
		let formData = new FormData();
		if(params['file'] != null){
			formData.append("content[file]", params['file']);
			params['file'].delete
		}
		for ( let key in params ) {
			formData.append("content[" + key + "]", params[key]);
		}
		return Http.post('user/contents.json?', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},

	upload(file) {
		var formData = new FormData();
		formData.append("content[file]", file);
		return Http.post('user/contents.json?editor_uploaded=true', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
			});
	},

	update(id, params) {
		if(params['metadata'] != null) {
		  params['metadata'] = JSON.stringify(params['metadata'])
		}
		let formData = new FormData();
		if(params['file'] != null){
			formData.append("content[file]", params['file']);
			params['file'].delete
		}
		for ( let key in params ) {
			formData.append("content[" + key + "]", params[key]);
		}
		return Http.put('user/contents/' + id + '.json?editor_uploaded=true', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},



	destroy(id) {
		return Http.delete('user/contents/' + id + '.json');
	},

	restore(id) {
		return Http.post('user/contents/' + id + '/recover.json');
	},

	delete_all(ids) {
		return Http.delete('user/contents/delete_all', {data: { content_ids: ids } });
	},

	destroy_fully(ids) {
		return Http.delete('user/contents/destroy_fully', {data: { content_ids: ids } });
	},

	search(query) {
		return Http.get('user/contents.json?',{ params: {query: query} });
	},

	renew(id) {
		return Http.post('user/contents/' + id + '/renew.json');
	},
};

export default ContentService;
