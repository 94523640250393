const messages = {
	common_success_messages: {
		qr_code_copied: 'QR code copied',
	},
	uploading: {
		drop_file_here: 'Drop Files Here',
	},
	file_conversion: {
		processing_video: 'Processing Video',
		processing_video_message: 'Please wait until we complete processing file.',
	},
	common_error_messages: {
		internet_not_available: 'No Network available.'
	},
	common_words: {
		previewing: 'Previewing:',
		app: 'App',
		type: 'Type:',
		template: 'Template',
		widget: 'Widget',
		channel: 'Channel',
		to: 'To',
		or: 'OR',
		save: 'Save',
		ok: 'Ok',
		cancel: 'Cancel',
		loading: 'Loading...',
		app_name: 'CMS Signage',
		file: 'File',
		submit: 'Submit',
		email: 'Email',
		add: 'Add',
		actions: 'Actions',
		action: 'Action',
		done: 'Done',
		success_msg: 'Changes have been saved',
		show_details: 'Show Detail',
		duration_unit: '(In seconds)',
		duration_unit_website_refresh: '(In seconds, 0 for no refresh)',
		delete: 'Delete',
		rename: 'Rename',
		update: 'Update',
		duplicate: 'Duplicate',
		edit: 'Edit',
		search: '🔍 Search',
		no_search_result: 'No search result for',
		last_edited: 'Last edited: ',
		view_report: 'View Report',
		load: "Load",
		none: "None",
		step: 'Next',
		select: 'Select contents',
		deselect: 'Deselect all',
	},
	side_bar_tabs: {
		screens: 'Screens',
		library: 'Contents',
		widgets: 'Apps',
		templates: 'Templates',
		playlists: 'Playlists',
		channels: 'Channels',
		schedules: 'Schedules',
		branding: 'Branding',
		reports: 'Reports',
		alerts: 'Notifications',
		users: 'Users',
		billing: 'Billing',
		subscription: 'Subscription',
		support: 'Support',
		users: 'Users',
		logout: 'Logout'
	},
	nav_bar: {
		profile: 'Profile',
		logout: 'Logout'
	},
	screens: {
		title: 'How to add a new screen? Quite simple',
		screen_group_tab_title: ' Screens Group',
		how_to_enroll_title: 'How to enroll your first device?',
		step_1: '1. Scan QR code with CMSSignage android app.',
		step_2: '2. Use provided token below QR code, with CMSSignage android app.',
		copy_token_tooltip: 'Copy token to clipboard',
		rename: 'Rename Device',
		step1: {
				title: 'Step 1',
				heading: 'Install CMS Signage App <br/>on your screen from respective appstore'
		},
		step2:  {
				title: 'Step 2',
				heading: 'On App launch paring OTP will shown'
		},
		step3: {
				title: 'Step 3',
				heading: "Press 'Add new screen' button"
		},
		details: {
			os_type: 'OS Version',
			last_seen: 'Last Seen',
			online: 'Online',
			screen_group: 'Screen Group',
			app_version: 'App Version',
			rotation: 'Orientation',
			refresh: 'Refresh',
			subscription: "Subscription Status",
			set_live_mode: 'Set To Live Mode'
		},
		otp_placeholder: 'Screen OTP e.g 3428',
		no_data: 'No screens paired yet, follow the instruction on the screens group tab, how to add one',
		no_screens_next_step: '<i class="zmdi zmdi-run"></i><b>Next step:</b> Add some fabulous items to contents section',
		name: 'Name',
		name_placeholder: 'E.g. Entrance screen 1',
    portrait: '90',
		upside_down: '270',
		landscape_left: '0',
		landscape_right: '180'

	},
	screen_group: {
		new_group: 'New group',
		name: 'Name :',
		total_screen: 'Total screens :',
		created: 'Created :',
		add_device: 'Add new screen',
		set_media_title: 'Select what to play',
		media_title: 'Now Playing',
		media_not_available: 'Click here & select what to play',
		rename: 'Rename Screen Group',
		created_succes: 'Screen group created',
		renamed_succes: 'Screen group renamed',
	  media_options: {
		  content: 'Select content',
		  playlist: 'Select playlist',
		  grouped_schedule: 'Select schedule',
		  nocontent: 'No Content exists',
			noplaylist: 'No Playlist exists',
			nogrouped_schedule: 'No Schedule exists'
		}
	},
	change_media: {
		media_type: 'Media type',
		media: "Search and select",
	},
	add_device_dialog: {
		otp: 'CMS Signage App paring OTP',
		otp_placeholder: 'Screen OTP',
		preview_mode: 'Testing Mode (Skip billing) ',
		add_group_title: ' Add new screen to group',
		device_added_succes: "Screen is paired"
	},
	rename_device_dialog:{
		device_name: 'Name',
		rename: 'Screem Rename'
	},
	create_group_dialog: {
		group_name: 'Group name',
		group_name_placeholder: 'E.g. first-floor screens',
		create_group_title: 'New screens group'
	},
	faqs: {
		title: 'FAQs',
		que1: 'Q: Where is my payment details stored?',
		que3: 'Q: Can I unsubscribe at any time?',
		que4: 'Q: Where is my uploaded content stored?',
		que5: 'Q: What if I have other questions?',
		ans1: "The security of payment details is our topmost priority. we use <a href='https://stripe.com' target='_blank'>stripe.com</a> highly secure payment gateway, we don't store your payment details on our server. Full PCI compliance, Read more about stripe security <a href='https://stripe.com/docs/security/stripe' target='_blank'>stripe security</a>.",
		ans3: "Yes, you can, we recommend doing this when nearing your billing cycle",
		ans4: 'We use AWS cloud-hosted in  US regions, to store your playlist files.',
		ans5: "Live chat with us or Drop a mail <a href='mailto:ratna@cmssignage.com?Subject=Hello%20CMSsignage'>ratna@cmssignage.com</a>"
	},
	content: {
		created_at: 'Created:',
		title: 'Contents',
		size: 'Size',
		upload_btn: 'Upload files',
		upload_progress_message: 'Uploaded {files_uploaded} of {total_files}',
		no_content_title: '<b>Help Tips:</b>&nbsp;Contents section contains uploaded files, installed <b>Apps</b> and <b>Templates</b>',
		no_content: 'No contents, start with uploading some files <i class="zmdi zmdi-plus-circle"></i>',
		no_content_next_step: '<i class="zmdi zmdi-run"></i> &nbsp;<b>Next step:</b> Configure some apps & templates, later create playlist using these contents',
		edit_file: 'Edit Content',
		upload_completed: 'Upload Completed',
		previewer: 'Preview',
		widget: 'App',
		widget_template: 'Template',
		location: 'Location',
		set_expiration: 'Set expiration date time',
		expire_content: 'Expire Content',
		expired_content_title: 'Expired Contents',
		expiration_time_placeholder: 'Set Expiration Time',
		remove_expiration: 'Remove Expiration',
		deleted_content_title: 'Trash',
	},
	upload: {
		upload: "Upload",
		upload_more: "Upload more",
		upload_selection_window_title: "Upload files",
		file_type_empty_title: "Select files to upload",
		file_type_empty_description: "Alternatively, Drag and drop your files here",
		file_type_empty_button_text: "Browse",
		uploading_count: "Files {uploaded_count} of {total_files} uploaded",
		all_files_uploaded: "All files uploaded",
		few_files_upload_failed: "{failed_count} file failed to upload",
		upload_size: "Size: {size}",
		size_limit_exceed: "{size} MB upload limit, contact ratna@cmssignage.com to increase the limit.",
		upload_failed: "Upload failed!!!",
	},
	playlist: {
		title: 'Playlists',
		no_data: 'Playlists not available',
		not_selected: 'Playlist not selected',
		no_media_files: 'No media files found',
		create_playlist: 'Create playlist',
		playlist_name: 'Playlist name',
		select_device_group: 'Select device group',
		duration: 'Duration:',
		duration_help_text: 'The number of seconds stays on the screen before moving to the next content',
		duration_help_text_playlist: 'Total play duration for this playlist, in seconds',
		resize_mode: 'Resize mode:',
		name: 'Name',
		duration: 'Duration',
		resize: 'Resize',
		drag_drop_hint_text: 'Click or Drag and drop to upload files',
		new_playlist: 'New playlist',
		groups: 'Groups',
		contents: 'Total Contents',
		delete_playlist_confirmation_title: 'Delete playlist',
		delete_playlist_confirmation_body: 'You will not be able to recover this playlist',
		delete_playlist_yes_button_text: 'Yes, delete it',
		delete_playlist_no_button_text: 'No, keep it',
		deleted_successfully: 'Playlist deleted',
		contents_count: 'Contents: ',
		contents: {
			title: 'Playlist Content',
			no_data: 'No Contents Available. Drag and Drop content here.',
			total_duration: 'Total Duration:'
		},
		no_data: {
			title: '<b>Help Tips:</b>&nbsp;A playlist is a collection of item, from contents section.',
			action: 'No playlist, start with one <i class="zmdi zmdi-plus-circle"></i>',
			no_playlist_next_step: '<i class="zmdi zmdi-run"></i>&nbsp;<b>Next step:</b> Create schedules',
		},
		used_in: 'Used in:'

	},
	playlist_content: {
		no_content:{
			msg1: "Drag and Drop from the contents section over here",
			msg2: "You can re-position the playlist item by drag and drop for play sequence"
		}
	},
	channels:{
		title: 'Channels',
		new_channel: 'New channel'
	},
	channel: {
		created_at: 'Created:',
		title: 'Contents',
		size: 'Size',
		no_content_title: '<b>Help Tips:</b>&nbsp;Contents section contains uploaded files, installed <b>Apps</b> and <b>Templates</b>',
		no_content: 'No contents, start creating channels <i class="zmdi zmdi-plus-circle"></i>',
		edit_channel: 'Edit Channel',
		previewer: 'Preview',
		invalid_name_placeholder: 'Invalid name',
		name_placeholder: 'Channel Name',
		no_data: {
			title: '<b>Help Tips:</b>&nbsp;A channel is a collection of item, from contents section.',
			action: 'No channel, start with one <i class="zmdi zmdi-plus-circle"></i>',
		},
		media: {
			no_data: 'No media added. Please drag and drop content or channel to add media.',
			schedule: {
				title: 'Edit {schedule_type} Schedule',
				new_title: 'New {schedule_type} Schedule',
				start_date: 'Start date',
				end_date: 'End date',
				start_time: 'Start time',
				end_time: 'End time',
				create_schedule: 'Create schedule',
				new_schedule: 'New schedule',
				edit_title: 'Click to edit schedule',
				schedule_help_text: 'Media schedules detail',
				event_dialog: {
					title: 'Select Playlist'
				},
				schedule_types: {
					daily: 'Everyday',
					weekly: 'Weekly',
					specific_date: 'Specific Date'
				},
				button:{
					delete_tooltip: "Click here to delete schedule",
					edit_tooltip: "Click here to edit schedule"
				},
				select_type_heading: 'Please select the schedule type you want to create'

			},
		}
	},
	schedules: {
		title: 'Schedules',
		schedule_name: 'Schedule name',
		start_date: 'Start date',
		end_date: 'End date',
		start_time: 'Start time',
		end_time: 'End time',
		select_playlist: 'Select playlist',
		create_schedule: 'Create schedule',
		new_schedule: 'New schedule',
		edit_title: 'Click to edit schedule',
		playlist_count: 'Scheduled items: ',
		no_data: {
			no_content_title: '<b>Help Tips:</b>&nbsp;Plan your week, it will repeat the same for each week. Make sure playlists are created first.',
			action_title: 'No schedule, start with one <i class="zmdi zmdi-plus-circle"></i>',
			no_schedule_next_step: '&#9996;<b>Last step:</b> Now go back to Screens menu item, select what you want to play against screens group.',
		},
		select_playlist_label: 'Make sure playlist are created',
		event_dialog: {
			title: 'Select Playlist'
		},
		schedule_types: {
			date_view: 'Date View',
			week_view: 'Week View'
		},
		button:{
			delete_tooltip: "Click here to delete schedule",
			edit_tooltip: "Click here to edit schedule"
		}

	},
	branding: {
		name: 'Branding name',
		title: 'Branding',
		create: 'New Branding',
		primary_bg_color: 'Primary background color',
		secondary_bg_color: 'Secondary background color',
		title_text_color: 'Title text color',
		body_text_color: 'Body Text color',
		title_font_family: 'Title font family',
		body_font_family: 'Body font family',
		logo: 'Logo',
		select_logo: 'Select Logo',
		remove_logo: 'Remove Logo',
		default_branding: 'Active',
		set_default: 'Activate this Branding'
	},
	login: {
		forgot_password: {
			reset_your_password: 'Reset your password',
			send_reset_link: "Enter your email, we\'ll send you a link to reset password",
			your_email: 'Your email',
			reset_password: 'Reset password',
			success_message: 'You will receive a mail with reset password link, if email exists with us.'
		},
		reset_password: {
			reset_password_title: 'set new password',
			new_password: 'Enter new password',
			reset_password_button: 'Save',
			set_new_password_alert_title: 'Set new password',
			new_password_placeholder: 'New password',
			confirm_new_password_placeholder: 'Confirm new password',
			change_password_button: 'Change password',
		},

		sign_in: {
			sign_in_title: 'Sign in',
			email: 'Email',
			password: 'Password',
			remember_me: 'Remember me',
			no_account: "Don't have an account?",
			signup_button: 'Sign up',
			forgot_password: 'Forgot Password?',
		},



		sign_up: {
			sign_up_title: 'Sign up',
			organisation_name: 'Organization name (Optional)',
			new_password: 'Password',
			password_complexity_message: 'Minimum 8 characters at least, 16 or more is even better.',
			agree_with_the: 'Agree with the',
			terms_and_conditions: 'terms and conditions',
			sign_up_button: 'Sign up',
			already_have_account: 'Already have an account?',
		},

	},
	confirmation_alert: {
		title: 'Are you sure?',
		yes: 'Yes',
		no: 'No',
		yes_delete:'Yes, move it!',
		no_keep_it: 'No, keep it',
		playlist_delete_message: 'You will not be able to recover this Playlist',
		schedule_delete_message: 'You will not be able to recover this Schedule',
		branding_delete_message: 'You will not be able to recover this Branding',
		channel_delete_message: 'You will not be able to recover this Channel',
		device_delete_message: 'Along with delete, Client App on a device will be unlinked from your account',
		screen_group_delete_message: 'Along with this group, all screens of this group will be deleted too',
		content_delete_message: 'You will not be able to recover this content',
		content_trash_message: 'Move these contents to Trash',
		subscription_delete_message: 'Your subscription would be still active till end of upcoming billing date, later your screens will stop working',
		subscription_reactivate_message: 'Your subscription will be activated and charged on due date',
		input_validation_message: 'Please type delete to confirm'
	},
	user: {
		delete_account_title:'Account deletion process',
		delete_account_message: 'Please drop us a mail at ratna@cmssignage.com for an account deletion request.',
		title: 'Users',
		contents: {
			title: 'Contents',
			duration: 'Duration',
			resize_mode: 'Resize',
			widget: {
				rss_feed: {
					feeds_on_screen: 'No of feeds on screen',
					duration_on_screen: 'Duration on screen',
					next_feed_duration: "Next feed duration"
				}
			}
		},
		reports: {
			title: 'Content played',
			search_filter_header: 'Filter Reports',
			no_data_available: 'No reports to display'
		},
		notification: {
			label: {
				name: 'Name',
				last_seen: "Last Seen",
				schedule: "Schedule For",
				daily: "Every day",
				weekly: "Every Monday",
				monthly: "1st of each month"
			},
			title: 'Notifications',
			save_button: "Create Alert",
			no_data: "No alerts!!!"
		},
		edit_profile: 'Edit Profile',
		change_password: 'Change Password',
		old_password: 'Current Password',
		new_password: 'New Password',
		confirm_password: 'Confirm Password',
		organization_name: 'Organization name',
		update_profile_alert_title: 'Update profile',
		timezone: 'Timezone',
		password_changed_succcess_msg: 'Password changed successfully',
		delete_account: 'Delete User',
	},
	widget: {
		refresh_preview: 'Refresh preview',
		content_name_placeholder: 'Name this content',
		content_invalid_name_placeholder: 'Invalid content name',
		preview: 'Preview',
		title: 'Widgets',
		apps_title: 'Apps',
		add_widget: 'Install App',
		widget_name: 'Name',
		branding: 'Branding',
		template: {
			title: 'Templates',
			use_template: 'Edit & Use',
			select_layout: "Select Layout"
		},
		blank_editor:{
			title: 'Blank Editor',
			description: 'Design something cool from scratch your own.',
			dialog_title: 'Add Html Content'
		},
		birthday_wish: {
			title: 'Birthday Wish',
			description: 'Standard birthday wish template.'
		},
		testimonials: {
			title: 'Testimonials',
			description: 'Display feedbacks from your customers.'
		},
		announcement: {
			title: 'Announcement',
			description: 'Display announcements & Information.'
		},
		greeting: {
			title: 'Greeting Message',
			description: 'Welcome your guest with beautiful message.'
		},
		offer: {
			title: 'Offer Announcement',
			description: 'Display latest offers information.'
		},
		rss_feed:{
			title: 'RSS feed',
			description: 'Display live updates from any RSS feed url',
			//url field
			url_label_text: 'Feed URL',
			url_field_placeholder_text: 'E.g https://rss.nytimes.com/rss/World.xml',
			url_validation_error_text: 'URL is required',
			//next feed duration field
			next_feed_duration_text: 'Next feed duration',
			next_feed_duration_hint_text: '(in seconds, when should move to next feed)',
			next_feed_duration_validation_error_text: 'Duration is required'
		},
		weather:{
			title: 'Weather',
			description: 'Display live weather report',

			location_label_text: 'Location name',
			location_placeholder_text: 'E.g London',
			location_validation_error_text: 'Location name is required',
		},
		analog_clock:{
			title: 'Analog clock',
			description: 'Display anaglo clock',
			dialog_title: 'Analog clock'
		},
		quotes:{
			title: 'Motivational quotes',
			description: 'Display motivational quotes',
			
			next_quote_label_text: 'Next quote duration',
			next_quote_placeholder_text: 'E.g 30',
			next_quote_validation_error_text: 'Duration is required',
		},
		bibleverse:{
			title: 'Bible verse',
			description: 'Display bible verse',
			
			next_verse_label_text: 'Next verse duration',
			next_verse_placeholder_text: 'E.g 30',
			next_verse_validation_error_text: 'Duration is required',
		},
		currency:{
			title: 'Exchange rate',
			description: 'Display currency exchange rate in real-time',
			dialog_title: 'Currency exchange rate',
			base_currency: 'Base currency',
			conversation_currencies: 'Conversion currencies',
			base_currency_hint: 'E.g USD',
			conversation_currencies_hint: 'E.g JPN,INR,HRK',
			no_white_space: "(No space b/w comma)"
		},
		countdown:{
			title: 'Countdown',
			description: 'Display countdown timer for offers, birthday',
			
			message_label_text: 'Messsage',
			message_placeholder_text: 'E.g 10% discount offer ending in',
			messag_validation_error_text: 'Message is required',

			future_date_label_text: 'Countdown ending on',
			future_date_placeholder_text: 'Future date',
			future_date_validation_error_text:'Date is required',

		},
		countup:{
			title: 'Countup',
			description: 'Display countup timer for last singup, bug reported',
			
			message_label_text: 'Messsage',
			message_placeholder_text: 'E.g Days elapsed since last reported incident',
			messag_validation_error_text: 'Message is required',

			past_date_label_text: 'Countdup started on',
			past_date_placeholder_text: 'Past date',
			past_date_validation_error_text:'Date is required',
		},
		twitter:{
			title: 'Twitter',
			description: 'Display twitter feeds',
			dialog_title: 'Twitter'
		},
		facebook: {
			login_label: 'Permission',
			login_with_facebook: 'Login with facebook'
		},
		facebook_recommendation:{
			title: 'FB recommendation',
			description: 'Display facebook recommendation',
			dialog_title: 'Facebook recommendation',
			next_post_duration: 'Next post duration'
		},

		facebook_post:{
			login_label: 'Facebook',
			title: 'FB post',
			description: 'Display facebook page post',
			dialog_title: 'Facebook post'
		},

		website:{
			title: 'Website',
			description: 'Display website on screens',
			//url field
			url_label_text: 'Website URL',
			url_field_placeholder_text: 'E.g https://cmssignage.com',
			url_validation_error_text: 'URL is required',
			//next feed duration field
			refresh_duration_text: 'Page refresh duration',
			refresh_duration_hint_text: '(in seconds, 0 means no auto refresh)',
			refresh_duration_validation_error_text: 'Duration is required'
		},

		meeting_room:{
			title: 'Meeting room',
			description: 'Meeting room availability and bookings',
			
			login_label_text: 'Login',
			login_placeholder_text: 'Login to select either google or microsoft calendar',
			login_validation_error_text: 'Login required',

			room_name_label_text: 'Meeting room name',
			room_name_placeholder_text: 'Your given name to meeting room in your org.',
			room_name_validation_error_text: 'Meeting room name is required',
		},
		followus:{
			title: 'Follow Us',
			description: 'Display social media handles',
			category: 'Social',

			
			next_item_duration_text: 'Item duration',
			next_item_duration_hint_text: '(In seconds)',

			fb_url_label_text: 'Facebook',
			instagram_url_label_text: 'Instagram',
			youtube_url_label_text: 'YouTube',
			twitter_url_label_text: 'Twitter',

			placeholder_text:'Social media handle URL',

			mobile_label_text: 'Mobile',
			email_label_text: 'Email',
			website_label_text: 'Website',
			placeholder_mobile_text: 'Mobile number',
			placeholder_email_text: 'Email-id',
			placeholder_website_text: 'Website url',

			composite_element_validation_erro: 'At least one social meida handle required',

		},

		ocean_tide:{
			title: 'Ocean tide',
			description: 'Display live ocean tide report',

			station_name_label_text: 'Station name',
			station_name_placeholder_text: 'E.g London',
			station_name_validation_error_text: 'Station Name is required',


			station_code_label_text: 'Station Code',
			station_code_placeholder_text: '0126',
			station_code_validation_error_text: 'Station Code is required',

			bst_timezone_label_text: 'BST Timezone',
			bst_timezone_validation_error_text: 'BST Timezone is required',
		},

		marine_weather:{
			title: 'Marine Weather',
			description: 'Display live Marine weather report',

			location_label_text: 'Location name',
			location_placeholder_text: 'E.g London',
			location_validation_error_text: 'Location name is required',
		},
		
		demo_name:{
			title: 'Demo SVG template',
			description: 'test description',
			description_label: 'Demo SVG template desc',
			dialog_title: 'Facebook post'
		},
		instance_created: 'Saved & placed in contents section',
		instance_updated: 'Changes saved',
	},
	billing:{
		title: 'Plan'
	},
	subscription: {
		title: 'Subscriptions',
		plan: 'Plan',
		subscription_title: 'subscription',
		quantity_placeholder: '1',
		pay: 'Pay',
		upgrade:'Upgrade Plan to Yearly',
		downgrade: 'Downgrade Plan to Monthly',
		view_billing: 'View bills',
		refresh: 'Refresh',
		add_screen: 'Apply subscription on screens',
		per_month: 'Per month',
		quantity: 'Quantity',
		total_quantity: 'Total quantity',
		quantity_message: 'Eligible for billing screens',
		total_amount: 'Total amount',
		last_paid_amount: 'Last paid amount',
		credit_balance: 'Credit Balance',
		subscription_status: 'Status',
		downgrade_label: 'Downgrade',
		upgrade_label: 'Upgrade',
		pending_state: 'Pending',
		next_billing: 'Upcoming billing',
		cancelled_on: 'Cancelled on',
		total_subscribed: 'Total subscribed:',
		subscription_quantity: 'Subscription quantity:',
		cancel_subscription: 'Cancel subscription',
		cancelling_on: 'Cancelling on',
		resume: 'Reactivate Subscription',
		monthly: 'Monthly',
		selected_screen: 'Used quantity',
		unused_screen: 'Unused quantity',
		subscription_cancel_tooltip: 'Subscription active till {date}',
		subscription_updated_tooltip: 'Prorated charge will get added in upcoming billing cycle',
		upgrade_note: 'Upgrading: Once you approve your plan will change to Yearly billing cycle',
		downgrade_note: 'Downgrading: Once you approve your plan will change to Monthly billing cycle',
		empty_subscription_title: 'No subscriptions yet',
		empty_subscription_description: 'Once plans are bought they will appear in this section',

		trial_extended_banner_title: 'Congratulations!!! You have extended trial of {day} days',
		trial_extended_banner_description: 'Do contact ratna@cmssignage.com for any query or assistance',

		trial_started_banner_title: 'Welcome onboard!!! You have {day} days of 14 days trial',
		trial_started_banner_description: 'Do contact ratna@cmssignage.com for any query or assistance',
		trial_in_middle_banner_title: 'We believe by now, you are pro in using CMS Signage',
		trial_in_middle_banner_description: 'You are left with {day} days of 14 days trial',
		trial_nearing_end_banner_title: 'Trial ending in {day} days',
		trial_nearing_end_banner_description: 'Now is a good time to upgrade and get all the awesome benefits.',
		no_screen_linked_title: 'At least 1 linked screen required',
		no_screen_linked_description: 'You are billed based on the number of screens attached to your account, hence at least one required'
	},

	plan: {
		title: 'Plans',
		custom_plan_title: 'Custom',
		custom_plan_message: 'Tailor-made for you',
		custom_plan_screen_count: '50+ Screens',
		per_screen: 'Per screen',
		subscribe: 'Subscribe',
		contact: 'Contact sales team',
	},
	account_inactive:{
		msg1: "Your free 14 days trial ended :-(",
		msg2: "Select the 'View Billing' option to buy a plan or contact ratna@cmssignage.com for assistance",
		view_billing: "View Billing",
		msg3: "Your Subscription has expired :-(",
	},
	reports:{
		dashboard_title: 'Dashboard',
		title: 'Content played report',
		success_message: 'Report is building. We will notify you once the report is ready for download.',
		not_generated: 'In-progress...',
		content_label: 'Contents',
		device_label: 'Screens',
		date_range_label: 'Date Range',
		no_data: 'Note: Once report generated appears here for download',
		filters: 'Filters ({count})',
		created_at: 'Created At',
		download: 'Download',
		contents: 'Contents:',
		screens: 'Screens:',
		date_range: 'Date range:',
		button_text_generate_report: 'Generate Report',
	},
	dashboard:{
		screen: 'Screen',
		screens_group: 'Screens group',
		content_assigned: 'Content assigned',
		last_played_content: 'Last played',
		last_played_date: 'Last played date',
		last_seen: 'Last seen'
	},
	custom_alert: {
		notification_title: 'Notification',
		title: 'Set alerts',
		conditions: "Active alerts ({count})",
		label: {
			name: 'Name your alert',
			alert_name_placeholder: 'E.g. Offline 5 days',
			screens: 'For screens',
			last_seen: "Offline days",
			last_seen_alert_item: "Offline days:",
			name_alert_item: "Alert name:",
			schedule: "Get notified",
			daily: "Every day",
			weekly: "Every Monday",
			monthly: "1st of each month",
			days: "{days} days ago",
			notification_message: "Screens not in sync with server from last {day} days",
			notification_type_offline_screens: "Offline screens",
			created_at: "Created on"
		},
		save_button: "Create Alert",
		delete_message: 'This will delete notifications as well against this alert',
		no_data: "Alerts not created yet"
	},
}

export default messages;
